
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { AesRandomizerService } from './randomizer_service/aes-randomizer.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'master',
    loadChildren: () =>
      import('./mastermodule/mastermodule.module').then((m) => m.MastermodulePageModule),
  },
  {
    path: 'mod0101',
    loadChildren: () =>
      import('./mod0101/mod0101.module').then((m) => m.Mod0101PageModule),
  },
  {
    path: 'mod0210',
    loadChildren: () =>
      import('./mod0210/mod0210.module').then((m) => m.Mod0210PageModule),
  },
  {
    path: 'mod0223',
    loadChildren: () =>
      import('./mod0223/mod0223.module').then((m) => m.Mod0223PageModule),
  },
  {
    path: 'mod0224',
    loadChildren: () => import('./mod0224/mod0224.module').then(m => m.Mod0224PageModule)
  },
  {
    path: 'mod0225',
    loadChildren: () => import('./mod0225/mod0225.module').then(m => m.Mod0225PageModule)
  },
  {
    path: 'mod0226',
    loadChildren: () => import('./mod0226/mod0226.module').then(m => m.Mod0226PageModule)
  },
  {
    path: 'mod0227',
    loadChildren: () => import('./mod0227/mod0227.module').then(m => m.Mod0227PageModule)
  },

  {
    path: 'mod0165',
    loadChildren: () => import('./mod0165/mod0165.module').then(m => m.Mod0165PageModule)
  },
  {
    path: 'mod0228',
    loadChildren: () => import('./mod0228/mod0228.module').then(m => m.Mod0228PageModule)
  },

  {
    path: 'mod0129',
    loadChildren: () => import('./mod0129/mod0129.module').then(m => m.Mod0129PageModule)
  },

  {
    path: 'mod0113',
    loadChildren: () => import('./mod0113/mod0113.module').then(m => m.Mod0113PageModule)
  },
  {
    path: 'mod0118',
    loadChildren: () => import('./mod0118/mod0118.module').then(m => m.Mod0118PageModule)
  },
  {
    path: 'mod0103',
    loadChildren: () => import('./mod0103/mod0103.module').then(m => m.Mod0103PageModule)
  },

  {
    path: 'mod0165',
    loadChildren: () => import('./mod0165/mod0165.module').then(m => m.Mod0165PageModule)
  },
  {
    path: 'mod0228',
    loadChildren: () => import('./mod0228/mod0228.module').then(m => m.Mod0228PageModule)
  },
  {
    path: 'mod0229',
    loadChildren: () => import('./mod0229/mod0229.module').then(m => m.Mod0229PageModule)
  },


  {
    path: 'mod0230',
    loadChildren: () => import('./mod0230/mod0230.module').then(m => m.Mod0230PageModule)
  },
  {
    path: 'mod0113',
    loadChildren: () => import('./mod0113/mod0113.module').then(m => m.Mod0113PageModule)
  },
  {
    path: 'mod0118',
    loadChildren: () => import('./mod0118/mod0118.module').then(m => m.Mod0118PageModule)
  },
  {

    path: 'mod0130',
    loadChildren: () => import('./mod0130/mod0130.module').then(m => m.Mod0130PageModule)
  },
  {
    path: 'new-home',
    loadChildren: () => import('./new-home/new-home.module').then(m => m.NewHomePageModule)
  },
  {
    path: 'mod0231',
    loadChildren: () => import('./mod0231/mod0231.module').then(m => m.Mod0231PageModule)
  },
  {
    path: 'mod0232',
    loadChildren: () => import('./mod0232/mod0232.module').then(m => m.Mod0232PageModule)
  },
  {
    path: 'mod0233',
    loadChildren: () => import('./mod0233/mod0233.module').then(m => m.Mod0233PageModule)
  },
  {
    path: 'mod0234',
    loadChildren: () => import('./mod0234/mod0234.module').then(m => m.Mod0234PageModule)
  },
  {
    path: 'mod0235',
    loadChildren: () => import('./mod0235/mod0235.module').then(m => m.Mod0235PageModule)
  },
  {
    path: 'mod0236',
    loadChildren: () => import('./mod0236/mod0236.module').then(m => m.Mod0236PageModule)
  },
  {
    path: 'mod0237',
    loadChildren: () => import('./mod0237/mod0237.module').then(m => m.Mod0237PageModule)
  },
  {
    path: 'mod0238',
    loadChildren: () => import('./mod0238/mod0238.module').then(m => m.Mod0238PageModule)
  },
  {
    path: 'mod0239',
    loadChildren: () => import('./mod0239/mod0239.module').then(m => m.Mod0239PageModule)
  },
  {
    path: 'mod0240',
    loadChildren: () => import('./mod0240/mod0240.module').then(m => m.Mod0240PageModule)
  },
  {
    path: 'mod0241',
    loadChildren: () => import('./mod0241/mod0241.module').then(m => m.Mod0241PageModule)
  },
  {
    path: 'mod0242',
    loadChildren: () => import('./mod0242/mod0242.module').then(m => m.Mod0242PageModule)
  },
  {
    path: 'mod0243',
    loadChildren: () => import('./mod0243/mod0243.module').then(m => m.Mod0243PageModule)
  },
  {
    path: 'mod0244',
    loadChildren: () => import('./mod0244/mod0244.module').then(m => m.Mod0244PageModule)
  },
  {
    path: 'mod0245',
    loadChildren: () => import('./mod0245/mod0245.module').then(m => m.Mod0245PageModule)
  },
  {
    path: 'mod0246',
    loadChildren: () => import('./mod0246/mod0246.module').then(m => m.Mod0246PageModule)
  },
  {
    path: 'mod0247',
    loadChildren: () => import('./mod0247/mod0247.module').then(m => m.Mod0247PageModule)
  },
  {
    path: 'mod0248',
    loadChildren: () => import('./mod0248/mod0248.module').then( m => m.Mod0248PageModule)
  },
  {
    path: 'productsview',
    loadChildren: () => import('./productsview/productsview.module').then( m => m.ProductsviewPageModule)
  },
  {
    path: 'mod0249',
    loadChildren: () => import('./mod0249/mod0249.module').then( m => m.Mod0249PageModule)
  },
  {
    path: 'mod0250',
    loadChildren: () => import('./mod0250/mod0250.module').then( m => m.Mod0250PageModule)

  },
  {
    path: 'mod0251',
    loadChildren: () => import('./mod0251/mod0251.module').then( m => m.Mod0251PageModule)

  },
 
 
  {
    path: 'mod0252',
    loadChildren: () => import('./mod0252/mod0252.module').then( m => m.Mod0252PageModule)
  },
  // {
  //   path: 'allproductsview',
  //   loadChildren: () => import('./allproductsview/allproductsview.module').then( m => m.AllproductsviewPageModule)
  // },
  {
    path: 'mod0253',
    loadChildren: () => import('./mod0253/mod0253.module').then( m => m.Mod0253PageModule)
  },


 
 






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
